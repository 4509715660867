import AddAccountFail from "../../common/AddAccountFail";
import AddAccountSuccess from "../../common/AddAccountSuccess";
import Instagram2FA from "./steps/Instagram2FA";
import InstagramLoginContainer from "./steps/InstagramLoginContainer";
import { useInstagramWizard } from "./useInstagramWizard";

type InstagramWizardProps = {
  accountIdToReconnect: string | null | undefined;
};

const InstagramWizard: React.FC<InstagramWizardProps> = ({
  accountIdToReconnect,
}) => {
  const {
    accountId,
    error,
    isLoading,
    source2FA,
    step,
    handleLogin,
    handleSolveCheckpoint,
  } = useInstagramWizard();

  const handleCheckpointCallback = (code: string) => {
    if (accountId) {
      handleSolveCheckpoint({
        account_id: accountId,
        code,
        provider: "INSTAGRAM",
      });
    }
  };

  if (step === "LOGIN")
    return (
      <InstagramLoginContainer
        error={error}
        isLoading={isLoading}
        onSubmit={(data) => handleLogin(data, accountIdToReconnect)}
      />
    );

  if (step === "2FA" && accountId)
    return (
      <Instagram2FA
        accountId={accountId}
        error={error}
        isLoading={isLoading}
        onSubmit={handleCheckpointCallback}
        source={source2FA}
      />
    );

  if (step === "COMPLETED")
    return (
      <AddAccountSuccess isReconnect={accountIdToReconnect ? true : false} />
    );

  return <AddAccountFail />;
};

export default InstagramWizard;
