import { Shadows, ThemeOptions, createTheme, alpha } from '@mui/material';
import { blueGrey, common, green, grey, teal } from '@mui/material/colors';
import { deepmerge } from '@mui/utils';
declare module '@mui/material/styles' {
  interface Palette {
    linkedin?: Palette['primary'];
  }

  interface PaletteOptions {
    linkedin?: PaletteOptions['primary'];
  }
}

const themeOptionsBase: ThemeOptions = {
  shape: {
    borderRadius: 6,
  },
  shadows: [
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1
        ? '0px 1px 2px rgba(0, 0, 0, 0.05)'
        : i === 2
        ? 'rgba(0, 0, 0, 0.09) 0px 3px 12px'
        : i === 3
        ? 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px'
        : shadow
    ),
  ] as Shadows,
  spacing: 4,
  palette: {
    primary: {
      main: '#0E2936',
      light: '#153d51',
      dark: '#091b24',
      contrastText: common.white,
    },
    secondary: {
      main: teal[400],
    },
    divider: '#E4E4E7',
    background: {
      paper: grey[50],
    },
    // mode: "dark",
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontWeight: 800,
      fontSize: 48,
      lineHeight: 1,
      letterSpacing: -1.2,
    },
    h2: {
      fontWeight: 600,
      fontSize: 30,
      lineHeight: 1.2,
      letterSpacing: -0.75,
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 1.3,
      letterSpacing: -0.6,
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      letterSpacing: -0.6,
    },
    h5: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 1.6,
    },
    h6: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.6,
    },
    body2: {
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 13,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: 11,
      lineHeight: 1.6,
      fontWeight: 500,
    },
    overline: {
      fontSize: 13,
      lineHeight: 1,
      fontWeight: 500,
      textTransform: 'inherit',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          textTransform: 'inherit',
          fontWeight: 500,
          fontSize: 13,
          lineHeight: 1.7,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 5,
          paddingBottom: 6,
          transition: '0s',
        },
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.divider,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.divider,
            boxShadow: 'none',
          },
        }),
        outlined: ({ theme }) => ({
          borderWidth: 1,
          boxShadow: theme.shadows[1],
        }),
        contained: ({ theme }) => ({
          boxShadow: theme.shadows[1],
          '&:hover': {
            boxShadow: 'inherit',
          },
        }),
        text: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontWeight: 500,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: theme.shape.borderRadius,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          '&:hover': {
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
          },
          boxShadow: theme.shadows[1],
          padding: 6.5,
          transition: '0s',
        }),
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          ...(ownerState.selected && { backgroundColor: grey[50] }),
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 2,
          paddingBottom: 2,
          transition: '0s',
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          ...(ownerState.selected && { backgroundColor: grey[50] }),
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 2,
          paddingBottom: 2,
          transition: '0s',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: ({ theme }) => ({
          '.MuiAutocomplete-option': {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 2,
            paddingBottom: 2,
          },
          padding: theme.spacing(1),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 2,
          paddingBottom: 2,
          transition: '0s',
          fontWeight: 500,
          fontSize: 13,
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          minWidth: 0,
          paddingRight: 10,
        }),
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          minWidth: 0,
          paddingRight: 10,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.divider,
          boxShadow: theme.shadows[1],
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderWidth: 1,
          borderColor: theme.palette.divider,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          backgroundColor: theme.palette.background.default,
        }),
        outlined: ({ theme }) => ({
          borderWidth: 2,
          borderColor: theme.palette.divider,
          borderStyle: 'solid',
          boxShadow: 'none',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: grey[100],
          borderRadius: theme.shape.borderRadius * 2,
          padding: 5,
          minHeight: 0,
        }),
        indicator: ({ theme }) => ({
          backgroundColor: common.white,
          height: '100%',
          borderRadius: theme.shape.borderRadius,
          zIndex: 0,
          boxShadow: theme.shadows[1],
          borderColor: theme.palette.divider,
          borderWidth: 1,
          borderStyle: 'solid',
        }),
        flexContainer: {
          zIndex: 1,
          position: 'relative',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          minHeight: 0,
          paddingTop: 7.5,
          paddingBottom: 7.5,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: common.black,
        },
        tooltipPlacementBottom: {
          marginTop: '5px!important',
        },
        tooltipPlacementTop: {
          marginBottom: '5px!important',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          border: 'none',
          fontSize: 13,
          fontWeight: 500,
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20,
        },
        icon: {
          display: 'none',
        },
        action: {
          marginRight: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 13,
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:before': {
            borderColor: theme.palette.divider,
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme, ownerState }) => ({
          backdropFilter: 'blur(10px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          marginTop: ownerState.anchorOrigin.vertical === 'bottom' ? 3 : ownerState.anchorOrigin.vertical === 'top' ? -3 : 0,
          boxShadow: theme.shadows[2],
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backdropFilter: 'blur(10px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.6),
          boxShadow: theme.shadows[2],
        }),
        list: ({ theme }) => ({
          padding: theme.spacing(1),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(5),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadows[3],
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20,
        },
        label: {
          fontWeight: 600,
          fontSize: 11,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#ececec', 0.7),
          // backdropFilter: 'blur(4px)',
        },
        invisible: {
          background: 'transparent',
          backdropFilter: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        fontWeight: '500',
        fontSize: 13,
        underline: 'hover',
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 500,
          fontSize: 13,
        },
      },
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    divider: '#153d51',
    background: {
      default: '#091b24',
      paper: '#091b24',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.divider,
          borderRadius: theme.shape.borderRadius,
          padding: 5,
          minHeight: 0,
        }),
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          height: '100%',
          borderRadius: theme.shape.borderRadius,
          zIndex: 0,
          boxShadow: theme.shadows[1],
        }),
        flexContainer: {
          zIndex: 1,
          position: 'relative',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#091b24', 0.8),
          // backdropFilter: 'blur(4px)',
        },
        invisible: {
          background: 'transparent',
          backdropFilter: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadows[3],
          backgroundImage: 'none',
        }),
      },
    },
  },
};

export const lightTheme = createTheme(themeOptionsBase);

export const darkTheme = createTheme(deepmerge(themeOptionsBase, darkThemeOptions));
