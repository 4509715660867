import { Alert, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  Checkpoint2FASource,
  CodeCheckpointFormData,
  CheckpointStepProps,
} from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";
import CheckpointResendButton from "../../../CheckpointResendButton";

const Linkedin2FA = ({
  error,
  isLoading,
  onSubmit,
  accountId,
  source,
}: CheckpointStepProps & {
  source?: Checkpoint2FASource | undefined;
}) => {
  const { control, formState, handleSubmit } =
    useForm<CodeCheckpointFormData>();

  return (
    <Stack
      p={6}
      spacing={2}
      component="form"
      onSubmit={handleSubmit(({ code }) => onSubmit(code))}
    >
      <Typography variant="h3" pr={10}>
        Enter your 2FA code
      </Typography>
      <Typography variant="h6" pr={10}>
        {source === "SMS"
          ? "The code has been sent via SMS"
          : source === "APP"
          ? "The code is in your Authentication app"
          : "Check your SMS or Authentication app"}
      </Typography>
      <Stack spacing={6} pt={6}>
        <Controller
          name="code"
          control={control}
          defaultValue=""
          rules={{ required: `2FA Code is required` }}
          render={({ onChange, value, ...props }) => (
            <TextField
              id="code"
              placeholder="Code"
              value={value}
              onChange={onChange}
              {...props}
              error={!!formState.errors.code}
              helperText={formState.errors.code?.message}
            />
          )}
        />
        {error && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons submitText="Submit" isLoading={isLoading} />
        {source === "SMS" && (
          <CheckpointResendButton
            provider="LINKEDIN"
            account_id={accountId}
            label="Resend 2FA code"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Linkedin2FA;
