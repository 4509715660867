import { Alert, Stack, TextField } from "@mui/material";
import { MailReconnectAuthFormData } from "../../../type";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SubmitButtons from "../../common/SubmitButtons";
import { authenticateAccount } from "../../../api/login";
import { displayErrorMessage } from "../../utils";

export default function MailReconnectForm({
  onSuccess,
  accountIdToReconnect,
}: {
  onSuccess: () => void;
  accountIdToReconnect: string;
}) {
  const form = useForm<MailReconnectAuthFormData>({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const isError = !!error;
  const isLoading = !!loading;

  const handleSubmit = async (data: MailReconnectAuthFormData) => {
    setLoading(true);
    setError(undefined);
    try {
      await authenticateAccount({
        body: {
          provider: "MAIL",
          imap_password: data.password,
          smtp_password: data.password,
        },
        accountIdToReconnect,
      });

      onSuccess();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} style={{ width: "100%" }}>
      <Stack spacing={4}>
        <Controller
          name="password"
          control={form.control}
          defaultValue=""
          rules={{ required: "Password is required" }}
          render={({ onChange, value, ...props }) => (
            <TextField
              id="password"
              placeholder="Password"
              type="password"
              fullWidth
              value={value}
              onChange={onChange}
              {...props}
              error={!!form.formState.errors.password}
              helperText={form.formState.errors.password?.message}
            />
          )}
        />
        {isError && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons submitText="Login" isLoading={isLoading} />
      </Stack>
    </form>
  );
}
