import { AxiosError } from "axios";
import { LinkedinBasicAuthFormData, LinkedinCookieAuthFormData } from "../type";

export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;

  if (text.toLocaleLowerCase() === "whatsapp") return "WhatsApp";

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const displayErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    const errorDetails = error.response?.data.detail;
    const errorTitle = error.response?.data.title;
    return errorDetails ? errorDetails : errorTitle ? errorTitle : error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return "Unexpected error.";
};

export const handleLinkedinProxyValues = (
  data: LinkedinBasicAuthFormData | LinkedinCookieAuthFormData,
) => {
  return {
    protocol: data.protocol,
    port: Number(data.port),
    host: data.domain,
    username: data.usernameProxy,
    password: data.passwordProxy,
  };
};

export const removeTrailingSlash = (str: string) => {
  return str.replace(/\/+$/, "");
};

export function isValidUrl(url: string) {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  return urlRegex.test(url);
}
