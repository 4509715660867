import { Box, SxProps, Typography } from "@mui/material";
import React from "react";

type FieldsetProps = {
  title: string;
  TitleSxProps?: SxProps;
  FieldsetSxProps?: SxProps;
};

const Fieldset: React.FC<FieldsetProps> = ({
  children,
  title,
  TitleSxProps = {},
  FieldsetSxProps = {},
}) => {
  return (
    <Box
      component="fieldset"
      sx={{
        borderStyle: "solid",
        borderRadius: 0.75,
        borderWidth: 1,
        borderColor: "lightgray",
        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.075)",
        ...FieldsetSxProps,
      }}
    >
      <Typography
        component="legend"
        variant="caption"
        color="darkgray"
        sx={{ ...TitleSxProps }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default Fieldset;
