import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

export const ListItemIconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  return (
    <IconButton
      sx={{
        border: 'none',
        boxShadow: 'none',
        padding: 1,
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' },
      }}
      ref={ref}
      {...props}
    />
  );
});
