import { useState } from "react";
import { authenticateAccount, solveCheckpoint } from "../../../api/login";
import {
  AuthenticateRequestBody,
  CheckpointRequestBody,
  InstagramBasicAuthFormData,
  WizardSteps,
} from "../../../type";

export const useInstagramWizard = () => {
  const [accountId, setAccountId] = useState<string>();
  const [step, setStep] = useState<WizardSteps>("LOGIN");
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [source2FA, setSource2FA] = useState<"SMS" | "APP">();

  const handleLogin = async (
    { chats_sync, messages_sync, password, username }: InstagramBasicAuthFormData,
    accountIdToReconnect?: string | null | undefined,
  ) => {
    if (isLoading) return;

    try {
      setError(null);
      setIsLoading(true);

      let body: AuthenticateRequestBody = {
        provider: "INSTAGRAM",
        username,
        password,
        user_agent: navigator.userAgent,
        ...((!messages_sync || !chats_sync) && {
          sync_limit: {
            ...(!messages_sync && { messages: 0 }),
            ...(!chats_sync && { chats: 0 }),
          },
        }),
      };

      const response = await authenticateAccount({ body, accountIdToReconnect });
      setIsLoading(false);

      setAccountId(response.account_id);

      if (response.object === "AccountCreated" || response.object === "AccountReconnected")
        setStep("COMPLETED");
      else if (response.object === "Checkpoint" && response.checkpoint.type === "2FA") {
        setStep(response.checkpoint.type);
        setSource2FA(response.checkpoint.source);
      }
      return response;
    } catch (error) {
      setError(error instanceof Error ? error : new Error("Unexpected error"));
      setIsLoading(false);
    }
  };

  const handleSolveCheckpoint = async (body: CheckpointRequestBody) => {
    if (isLoading) return;

    try {
      if (error) setError(null);
      setIsLoading(true);
      const response = await solveCheckpoint(body);
      setIsLoading(false);

      if (response.object === "AccountCreated" || response.object === "AccountReconnected")
        setStep("COMPLETED");
      else if (response.object === "Checkpoint" && response.checkpoint.type === "2FA") {
        setStep(response.checkpoint.type);
        setSource2FA(response.checkpoint.source);
      }
      return response;
    } catch (error) {
      if (step === "CAPTCHA") setStep("LOGIN");
      setError(error instanceof Error ? error : new Error("Unexpected error"));
      setIsLoading(false);
      throw error;
    }
  };

  return {
    error,
    isLoading,
    step,
    accountId,
    handleSolveCheckpoint,
    handleLogin,
    source2FA,
  };
};
