import {
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import { getAccountStatus, getQrCode } from "../../../api/login";
import { AuthenticateResponse } from "../../../type";
import AddAccountSuccess from "../../common/AddAccountSuccess";
import Countdown from "../../common/Countdown";
import { displayErrorMessage } from "../../utils";

const WhatsAppWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect?: string | undefined | null;
}) => {
  const [isLoadingQr, setIsLoadingQr] = useState(true);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [qrcode, setQrCode] = useState("");
  const failureUrl = sessionStorage.getItem("failureUrl");

  const getQrCodeMutation = useMutation(
    async () => {
      return await getQrCode("WHATSAPP", accountIdToReconnect);
    },
    {
      onSuccess: (data: AuthenticateResponse) => {
        setIsLoadingQr(false);
        if (
          data.object === "Checkpoint" &&
          data.checkpoint.type === "QRCODE" &&
          data.checkpoint.qrcode
        ) {
          setQrCode(data.checkpoint.qrcode);
          getLoginStatusMutation.mutate(data.account_id);
        }
        setIsTimerEnded(false);
      },
    },
  );

  const getLoginStatusMutation = useMutation(async (account_id: string) => {
    return await getAccountStatus(account_id, accountIdToReconnect);
  });

  const handleGetQrCode = () => {
    getQrCodeMutation.mutate();
  };

  useEffect(() => {
    handleGetQrCode();
  }, []);

  const handleError = (error: unknown) => {
    if (
      error instanceof AxiosError &&
      error.response?.status === 504 &&
      error.response.statusText === "Gateway Timeout"
    ) {
      return "the QRcode is no longer valid. If you indeed scanned the QR Code, make sure it's the same account.";
    }

    return displayErrorMessage(error);
  };

  const handleRender = () => {
    if (getQrCodeMutation.isLoading || isLoadingQr) {
      return <CircularProgress />;
    }

    if (getLoginStatusMutation.isSuccess) {
      return (
        <AddAccountSuccess isReconnect={accountIdToReconnect ? true : false} />
      );
    }

    return (
      <>
        <Typography variant="h6" sx={{ marginBottom: 3 }}>
          Follow the steps to {accountIdToReconnect ? "reconnect" : "connect"}
        </Typography>
        {getQrCodeMutation.error && (
          <Typography color="error" sx={{ marginTop: 4 }}>
            Get Qrcode failed, {handleError(getQrCodeMutation.error)}
          </Typography>
        )}
        {getLoginStatusMutation.error && (
          <Typography color="error" textAlign="center">
            Connection failed, {handleError(getLoginStatusMutation.error)}
          </Typography>
        )}
        {isTimerEnded ? (
          <>
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleGetQrCode()}
              sx={{ textTransform: "uppercase", marginTop: 4 }}
            >
              Retry
            </Button>
          </>
        ) : (
          <>
            <InstructionsWhatsApp />
            {qrcode && <QrcodeWA qrcode={qrcode} />}
            <Countdown initialTime={60} setIsTimerEnded={setIsTimerEnded} />
            {failureUrl && failureUrl !== "undefined" && (
              <Link href={failureUrl} marginTop={4}>
                <Button variant="outlined" fullWidth>
                  Cancel
                </Button>
              </Link>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Stack p={6} justifyContent="center" alignItems="center">
      {handleRender()}
    </Stack>
  );
};

const QrcodeWA = ({ qrcode }: { qrcode: string }) => {
  useEffect(() => {
    QRCode.toCanvas(canvasRef.current, qrcode);
  }, [qrcode]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  return <canvas ref={canvasRef} width={276} height={276}></canvas>;
};

const InstructionsWhatsApp = () => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2">1. Open WhatsApp on your phone</Typography>
      <Typography variant="body2">
        2. Tap Menu or Settings and select Linked devices
      </Typography>
      <Typography variant="body2">3. Tap on Link a Device</Typography>
      <Typography variant="body2">
        4. Point your phone to this screen to capture the code (you have 60
        seconds before the qr code is no longer valid)
      </Typography>
    </Stack>
  );
};

export default WhatsAppWizard;
