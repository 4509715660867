import { Box, Button, Stack, SxProps } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

type LoginOptionsProps = {
  label: string;
  disabled?: boolean;
  ContentSxProps?: SxProps;
};

export const LoginOptions: React.FC<LoginOptionsProps> = ({
  label,
  children,
  disabled,
  ContentSxProps = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack sx={{ gap: 1 }}>
      {!disabled && (
        <>
          <Button
            sx={{ width: "fit-content" }}
            onClick={() => setIsOpen(!isOpen)}
            startIcon={isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          >
            {label}
          </Button>
          <Box
            sx={{
              display: isOpen ? "flex" : "none",
              flexDirection: "column",
              ...ContentSxProps,
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Stack>
  );
};
