import { Box } from "@mui/material";
import { ArkoseCaptchaData } from "apps/portalAccount/src/type";
import { useEffect } from "react";
import { makeEnforcementScript } from "./makeEnforcementScript";

type ArkoseLabsCaptchaProps = {
  data: ArkoseCaptchaData;
  onSuccess: (token: string) => void;
  onError: (error: Error | null) => void;
};

export const ArkoseLabsCaptcha: React.FC<ArkoseLabsCaptchaProps> = ({
  onError,
  onSuccess,
  data: { data, public_key },
}) => {
  useEffect(() => {
    const arkoseMessageHandler = (e: MessageEvent<any>) => {
      if (typeof e.data === "string") {
        const eventData = JSON.parse(e.data);
        if (isChallengeEvent(eventData)) {
          switch (eventData.eventId) {
            case "challenge-loaded":
              onError(null);
              break;
            case "challenge-complete":
              onSuccess(eventData.payload.sessionToken);
              window.removeEventListener("message", arkoseMessageHandler);
              break;
            case "challenge-error":
            case "challenge-failed":
              onError(new Error("Captcha resolution failed. Please retry."));
              break;
          }
        }
      }
    };
    window.addEventListener("message", arkoseMessageHandler);

    // Inject Arkose Labs enforcement script
    const setupEnforcementScript = document.createElement("script");
    setupEnforcementScript.textContent = makeEnforcementScript({
      targetElementId: "arkose",
      publicKey: public_key,
      data,
      startTime: Date.now(),
    });
    document.head.appendChild(setupEnforcementScript);

    // Load Arkose Labs game script
    const apiScript = document.createElement("script");
    apiScript.src = `//client-api.arkoselabs.com/v2/${public_key}/api.js`;
    apiScript.dataset["callback"] = "setupEnforcement";
    apiScript.defer = true;
    apiScript.async = true;
    document.head.appendChild(apiScript);

    return () => {
      window.removeEventListener("message", arkoseMessageHandler);
      setupEnforcementScript.remove();
      apiScript.remove();
    };
  }, []);

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Box id="arkose"></Box>
    </Box>
  );
};

type ArkoseChallengeEvent =
  | {
      eventId:
        | "challenge-loaded"
        | "challenge-complete"
        | "challenge-failed"
        | "challenge-shown"
        | "challenge-error";
      publicKey: string;
      payload: {
        sessionToken: string;
      };
    }
  | {
      eventId: "challenge-iframeSize";
      publicKey: string;
      payload: {
        frameHeight: number;
        frameWidth: number;
      };
    };

const isChallengeEvent = (data: unknown): data is ArkoseChallengeEvent => {
  return !!data && typeof data === "object" && "eventId" in data;
};
