import { Alert, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ArkoseCaptchaData, CheckpointStepProps } from "../../../../../type";
import SubmitButtons from "../../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../../utils";
import { ArkoseLabsCaptcha } from "./ArkoseLabsCaptcha";

interface LinkedinCheckpointProps extends CheckpointStepProps {
  data: ArkoseCaptchaData;
}

const LinkedinCaptcha = ({
  error,
  isLoading,
  data,
  onSubmit,
}: LinkedinCheckpointProps) => {
  const [captchaError, setCaptchaError] = useState<Error | null>(null);

  return (
    <Stack p={6} spacing={6}>
      <Typography variant="h6" pr={10}>
        Please solve the captcha to continue
      </Typography>

      {(error || captchaError) && (
        <Typography color="error" sx={{ marginY: "16px" }} textAlign="center">
          {displayErrorMessage(error || captchaError)}
        </Typography>
      )}

      <ArkoseLabsCaptcha
        data={data}
        onError={setCaptchaError}
        onSuccess={onSubmit}
      />

      {(error || captchaError) && (
        <Alert severity="error" variant="filled">
          {displayErrorMessage(error || captchaError)}
        </Alert>
      )}
      <SubmitButtons isLoading={isLoading} disableSubmit />
    </Stack>
  );
};

export default LinkedinCaptcha;
