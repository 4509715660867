import { Common } from "@unipile/types";
import AddAccountFail from "../../common/AddAccountFail";
import AddAccountSuccess from "../../common/AddAccountSuccess";
import Linkedin2FA from "./steps/Linkedin2FA";
import LinkedinCaptcha from "./steps/LinkedinCaptcha/LinkedinCaptcha";
import LinkedinContract from "./steps/LinkedinContract";
import LinkedinInAppValidation from "./steps/LinkedinInAppValidation";
import LinkedinLoginContainer from "./steps/LinkedinLoginContainer";
import LinkedinOTP from "./steps/LinkedinOTP";
import { useLinkedinWizard } from "./useLinkedinWizard";
import LinkedinPhoneRegister from "./steps/LinkedinPhoneRegister";

type LinkedinWizardProps = {
  accountIdToReconnect: string | null | undefined;
};

const LinkedinWizard: React.FC<LinkedinWizardProps> = ({
  accountIdToReconnect,
}) => {
  const {
    accountId,
    captchaData,
    contractList,
    source2FA,
    isLoading,
    error,
    handleLogin,
    handleSolveCheckpoint,
    handleWatchAccountStatus,
    step,
  } = useLinkedinWizard();

  const handleCheckpointCallback = (code: string) => {
    if (accountId) {
      handleSolveCheckpoint({
        account_id: accountId,
        code,
        provider: "LINKEDIN",
      });
    }
  };

  if (step === "LOGIN")
    return (
      <LinkedinLoginContainer
        error={error}
        isLoading={isLoading}
        onSubmit={(data) => handleLogin(data, accountIdToReconnect)}
      />
    );

  if (step === "2FA" && accountId)
    return (
      <Linkedin2FA
        accountId={accountId}
        error={error}
        isLoading={isLoading}
        onSubmit={handleCheckpointCallback}
        source={source2FA}
      />
    );

  if (step === "PHONE_REGISTER" && accountId)
    return (
      <LinkedinPhoneRegister
        error={error}
        isLoading={isLoading}
        onSubmit={handleCheckpointCallback}
      />
    );

  if (step === "OTP" && accountId)
    return (
      <LinkedinOTP
        accountId={accountId}
        error={error}
        isLoading={isLoading}
        onSubmit={handleCheckpointCallback}
      />
    );

  if (step === "IN_APP_VALIDATION" && accountId)
    return (
      <LinkedinInAppValidation
        accountId={accountId}
        onSubmit={() => handleWatchAccountStatus(accountIdToReconnect)}
      />
    );

  if (step === "CONTRACT_CHOOSER" && accountId && contractList)
    return (
      <LinkedinContract
        accountId={accountId}
        error={error}
        isLoading={isLoading}
        data={contractList}
        onSubmit={handleCheckpointCallback}
      />
    );

  if (step === "CAPTCHA" && accountId && captchaData)
    return (
      <LinkedinCaptcha
        accountId={accountId}
        error={error}
        isLoading={isLoading}
        data={captchaData}
        onSubmit={handleCheckpointCallback}
      />
    );

  if (step === "COMPLETED")
    return (
      <AddAccountSuccess isReconnect={accountIdToReconnect ? true : false} />
    );

  return <AddAccountFail />;
};

export default LinkedinWizard;
