import { Alert, Button, Link, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const AddAccountFail = ({ message }: { message?: string }) => {
  const [searchParams] = useSearchParams();
  const failureUrl: string =
    searchParams.get("url") || sessionStorage.getItem("failureUrl") || "";

  return (
    <Stack
      spacing={6}
      p={6}
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Alert severity="error" variant="filled">
        {message || "An error occurred during the connection please try again."}
      </Alert>
      {failureUrl && (
        <Link href={failureUrl}>
          <Button variant="outlined" fullWidth>
            Close
          </Button>
        </Link>
      )}
    </Stack>
  );
};

export default AddAccountFail;
