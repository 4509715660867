import { MailFullAuthFormData, WizardSteps } from "apps/portalAccount/src/type";
import { useState } from "react";
import AddAccountSuccess from "../../common/AddAccountSuccess";

import { Stack, Typography } from "@mui/material";
import MailBasicForm from "./MailBasicForm";
import MailFullForm from "./MailFullForm";
import MailReconnectForm from "./MailReconnectForm";

const MailWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect?: string | null | undefined;
}) => {
  const [step, setStep] = useState<WizardSteps>("LOGIN");
  const [fullFormData, setFullFormData] =
    useState<Partial<MailFullAuthFormData>>();

  if (step === "LOGIN") {
    return (
      <Stack p={6} spacing={3} alignItems="center">
        <Typography variant="h6">Enter your credentials</Typography>
        {accountIdToReconnect ? (
          <MailReconnectForm
            accountIdToReconnect={accountIdToReconnect}
            onSuccess={() => setStep("COMPLETED")}
          />
        ) : fullFormData ? (
          <MailFullForm
            defaultValues={fullFormData}
            onSuccess={() => setStep("COMPLETED")}
          />
        ) : (
          <MailBasicForm
            onNeedMoreInfos={setFullFormData}
            onSuccess={() => setStep("COMPLETED")}
          />
        )}
      </Stack>
    );
  } else if (step === "COMPLETED") {
    return <AddAccountSuccess isReconnect={!!accountIdToReconnect} />;
  } else return null;
};

export default MailWizard;
