import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

interface Props {
  initialTime: number;
  setIsTimerEnded: Dispatch<SetStateAction<boolean>>;
}

const Countdown = ({ initialTime, setIsTimerEnded }: Props) => {
  const increment = useRef<number | null>(null);
  const [timer, setTimer] = useState(initialTime);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerEnded(true);
    }

    increment.current = window.setInterval(() => {
      setTimer((timer) => {
        if (timer !== 0) return timer - 1;

        if (increment.current) {
          clearInterval(increment.current);
          setIsTimerEnded(true);
        }
        return timer;
      });
    }, 1000);

    return () => {
      if (increment.current) clearInterval(increment.current);
    };
  }, [initialTime, timer]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        border: 2,
        borderColor: "primary.main",
        marginTop: "8px",
      }}
    >
      <Typography fontWeight="bold">{timer}</Typography>
    </Stack>
  );
};

export default Countdown;
