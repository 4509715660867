import { Alert, Link } from "@mui/material";

export default function ICloudApplicationAlert() {
  return (
    <Alert severity="info" variant="filled">
      ICloud specific configuration
      <ul>
        <li>
          Sign in to your Apple account using your Apple ID:{" "}
          <Link href="https://appleid.apple.com/" target="_blank">
            Sign in
          </Link>
        </li>
        <li>
          Go to Account Security section and check two-factor authentication is
          activated. If not, please turn on to continue.
        </li>
        <li>
          Then go to App-specific Passwords section and click on “Generate an
          app-specific password”. Type Unipile and copy the app password
          generated. Copy or type it into the Password field below.
        </li>
      </ul>
    </Alert>
  );
}