import {
  DiscordSvg,
  GcalSvg,
  GmailSvg,
  IcloudSvg,
  InstagramSvg,
  LinkedinSvg,
  MailSvg,
  MessengerSvg,
  MobileSvg,
  OutlookSvg,
  SlackSvg,
  TelegramSvg,
  TwitterSvg,
  WhatsappSvg,
  ExchangeSvg,
} from './icons';

import { AccountType, UnreleasedAccountType } from '@focus-front/core';
export const AccountIcon: { [key in AccountType | UnreleasedAccountType]: string } = {
  GOOGLE: GmailSvg,
  GOOGLE_CALENDAR: GcalSvg,
  ICLOUD: IcloudSvg,
  INSTAGRAM: InstagramSvg,
  LINKEDIN: LinkedinSvg,
  MAIL: MailSvg,
  MESSENGER: MessengerSvg,
  OUTLOOK: OutlookSvg,
  WHATSAPP: WhatsappSvg,
  MOBILE: MobileSvg,
  TWITTER: TwitterSvg,
  DISCORD: DiscordSvg,
  SLACK: SlackSvg,
  TELEGRAM: TelegramSvg,
  EXCHANGE: ExchangeSvg,
};
