import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

const AddAccountSuccess = ({ isReconnect }: { isReconnect?: boolean }) => {
  const [searchParams] = useSearchParams();
  const bypassScreen = sessionStorage.getItem("bypassSuccessScreen");
  const successUrl: string =
    searchParams.get("url") || sessionStorage.getItem("successUrl") || "";

  if (successUrl && bypassScreen) {
    window.location.href = successUrl;
    return null;
  }

  return (
    <Stack
      spacing={6}
      p={6}
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <CheckCircleRoundedIcon color="success" fontSize="large" />
      <Typography variant="h6">
        Account successfully {isReconnect ? "reconnected" : "added"}!
      </Typography>
      {successUrl && (
        <Link href={successUrl}>
          <Button variant="outlined" fullWidth>
            Close
          </Button>
        </Link>
      )}
    </Stack>
  );
};

export default AddAccountSuccess;
