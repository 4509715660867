import {
  Alert,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  CheckpointStepProps,
  CodeCheckpointFormData,
  ContractListData,
} from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";

interface LinkedinContractProps extends CheckpointStepProps {
  data: ContractListData;
}

const LinkedinContract = ({
  error,
  isLoading,
  onSubmit,
  data,
}: LinkedinContractProps) => {
  const { control, handleSubmit } = useForm<CodeCheckpointFormData>();

  return (
    <Stack
      spacing={6}
      p={6}
      component="form"
      onSubmit={handleSubmit(({ code }) => onSubmit(code))}
    >
      <Typography variant="h6">Select your contract</Typography>

      <FormControl component="fieldset">
        <Controller
          rules={{ required: true }}
          control={control}
          name="code"
          defaultValue={data[0].id || ""}
          render={({ onChange, value, ...props }) => (
            <RadioGroup value={value} onChange={onChange}>
              {data.map((contract) => (
                <FormControlLabel
                  value={contract.id}
                  control={<Radio />}
                  label={`${contract.name} (${contract.id})`}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>

      {error && (
        <Alert severity="error" variant="filled">
          {displayErrorMessage(error)}
        </Alert>
      )}
      <SubmitButtons submitText="Submit" isLoading={isLoading} />
    </Stack>
  );
};

export default LinkedinContract;
