import { Alert, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CodeCheckpointFormData, CheckpointStepProps } from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";
import LinkedinResendButton from "../../../CheckpointResendButton";

const LinkedinOTP = ({
  onSubmit,
  error,
  accountId,
  isLoading,
}: CheckpointStepProps) => {
  const { control, formState, handleSubmit } =
    useForm<CodeCheckpointFormData>();

  return (
    <Stack
      spacing={6}
      p={6}
      component="form"
      onSubmit={handleSubmit(({ code }) => onSubmit(code))}
    >
      <Typography variant="h3" pr={10}>
        Enter your OTP code
      </Typography>
      <Typography variant="h6" pr={10}>
        Check your email
      </Typography>
      <Stack spacing={6} pt={6}>
        <Controller
          name="code"
          control={control}
          defaultValue=""
          rules={{ required: `OTP Code is required` }}
          render={({ onChange, value, ...props }) => (
            <TextField
              id="code"
              placeholder="Code"
              value={value}
              onChange={onChange}
              {...props}
              error={!!formState.errors.code}
              helperText={formState.errors.code?.message}
            />
          )}
        />
        {error && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons submitText="Submit" isLoading={isLoading} />
        <LinkedinResendButton provider="LINKEDIN" account_id={accountId} label="Resend OTP code" />
      </Stack>
    </Stack>
  );
};

export default LinkedinOTP;
