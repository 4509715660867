import dayjs from 'dayjs';
import React, { ReactNode, useRef, useState } from 'react';

import { MoreHoriz, Reply } from '@mui/icons-material';
import { Box, Chip, IconButton, ListItem, Stack, styled, Tooltip, Typography, TypographyProps } from '@mui/material';

import { AttendeeAvatar } from './AttendeeAvatar';
import { ImStatus, ImStatusIcon } from './ImStatusIcon';
import { ImInfo } from './ImInfo';
import { ImEmojiPicker } from './ImEmojiPicker';
import Linkify from 'linkify-react';
import { Opts } from 'linkifyjs';
import AddReactionIcon from '@mui/icons-material/AddReactionOutlined';
import { FilterPopper } from './FilterPopper';
import { EmojiSelection } from './EmojiMart';
import { ListItemIconButton } from './ListItemIconButton';

const linkifyOptions: Opts = {
  defaultProtocol: 'https',
  truncate: 64,
  //   attributes: linkProps,
};

export type IdentifierType =
  | 'PHONE_NUMBER'
  | 'EMAIL_ADDRESS'
  | 'MESSENGER_ID'
  | 'MESSENGER_THREAD_ID'
  | 'TIKTOK_ID'
  | 'TIKTOK_THREAD_ID'
  | 'TWITTER_ID'
  | 'TWITTER_THREAD_ID'
  | 'INSTAGRAM_ID'
  | 'INSTAGRAM_THREAD_ID'
  | 'LINKEDIN_ID'
  | 'LINKEDIN_THREAD_ID'
  | 'GROUP_THREAD';

const UserName = styled('div')({
  fontSize: 12,
  fontWeight: 500,
  opacity: 0.5,
});

const Message = ({ sx, children, ...rest }: TypographyProps) => (
  <Typography
    variant="body2"
    sx={{
      padding: '6px 12px',
      wordBreak: 'normal',
      overflowWrap: 'anywhere',
      whiteSpace: 'pre-wrap',
      '& a': {
        color: 'inherit',
      },
      ...sx,
    }}
    {...rest}
  >
    {/* {children} */}
    <Linkify options={linkifyOptions}>{children}</Linkify>
  </Typography>
);

type Props = {
  message: string | null | undefined;
  subtext?: string | undefined;
  is_sender: boolean;
  Actions: ReactNode;
  onReact?: (emoji: EmojiSelection) => void;
  onReply?: () => void;
  onMore?: () => void;
  username: string;
  avatar_url: string;
  show_name?: boolean;
  show_avatar?: boolean;
  show_date?: boolean;
  timestamp: string;
  status?: ImStatus;
  reply_to?:
    | {
        name: string;
        message: string | null;
        Attachments?: JSX.Element[];
      }
    | undefined;
  status_tooltip?: React.ReactNode | undefined;
  override?: string;
  Attachments?: JSX.Element[] | undefined;
  Audio?: JSX.Element | undefined;
  Reactions?: JSX.Element | undefined;
  Replies?: JSX.Element | undefined;
  addReactionTooltip?: string | undefined;
  quoteTooltip?: string | undefined;
  channelMode?: boolean;
};
export function ImBubbleContainer({
  message,
  subtext,
  is_sender,
  onReact,
  onReply,
  onMore,
  Actions,
  username,
  avatar_url,
  Reactions,
  show_avatar,
  show_name,
  show_date,
  timestamp,
  status,
  reply_to,
  status_tooltip,
  override,
  Audio,
  Attachments,
  addReactionTooltip,
  quoteTooltip,
  Replies,
  channelMode,
}: // account_id,
Props) {
  const [isHovering, setIsHovering] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);

  function _renderDate() {
    if (show_date) return <ImInfo>{dayjs(timestamp).format('DD/MM/YYYY')}</ImInfo>;
  }

  function _renderEmojiPicker() {
    return (
      <ImEmojiPicker
        title={addReactionTooltip}
        handleEmojiSelect={onReact}
        transformOrigin={{
          horizontal: is_sender ? 'right' : 'left',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: is_sender ? 'right' : 'left',
          vertical: 'top',
        }}
        Icon={<AddReactionIcon sx={{ fontSize: 18 }} />}
        size="small"
        sxIconButton={{
          border: 'none',
          boxShadow: 'none',
          padding: 1,
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' },
        }}
      />
    );
  }

  if (channelMode)
    return (
      <>
        {_renderDate()}
        <Stack
          direction="row"
          mt={show_avatar ? 2 : 0}
          px={4}
          pt={show_avatar ? 2 : 0}
          sx={{ width: '100%', ...(isHovering && { bgcolor: 'background.paper' }) }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          position="relative"
          spacing={1}
        >
          {show_avatar && (
            <AttendeeAvatar
              size={'tiny'}
              display_name={username}
              avatar_url={avatar_url}
              sx={{
                flexShrink: 0,
              }}
            />
          )}
          <Stack sx={{ width: '100%', ml: !show_avatar ? '32px' : 0, pl: 2, pr: 3 }}>
            {show_name && (
              <Stack direction="row">
                <Typography variant="subtitle2">{username}</Typography>
                <Typography variant="caption" sx={{ ml: 1 }} color="text.secondary">
                  {dayjs(timestamp).format('HH:mm')}
                </Typography>
              </Stack>
            )}
            {reply_to && (
              <Box
                sx={{
                  bgcolor: 'background.default',
                  borderRadius: '14px',
                  borderWidth: 3,
                  borderStyle: 'solid',
                  borderColor: 'background.paper',
                  p: 1,
                  pt: 0,
                }}
              >
                <Typography component="div" variant="caption" color="primary">
                  {reply_to.name}
                </Typography>
                {reply_to.Attachments}
                <Message sx={{ padding: 0 }}>{reply_to.message}</Message>
              </Box>
            )}
            <Box>
              {Audio}
              {message && <Message sx={{ padding: '3px 0px' }}>{message}</Message>}
            </Box>
            {!!Attachments?.length && (
              <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1} my={1}>
                {Attachments}
              </Box>
            )}
            {/* <Stack direction="row" flexWrap="wrap" spacing={1} sx={{ maxWidth: '90%', maxHeight: 400 }}></Stack> */}

            {Reactions && <Stack direction="row">{Reactions}</Stack>}
            {Replies && (
              <Stack
                direction="row"
                sx={{
                  mt: '2px',
                }}
              >
                {Replies}
              </Stack>
            )}
            {isHovering && (
              <Stack
                direction="row"
                className="actions"
                sx={{
                  position: 'absolute',
                  right: 24,
                  top: -5,
                  '& > *': {
                    opacity: 1,
                  },
                  zIndex: 5,
                }}
                spacing={2}
              >
                {onReact && _renderEmojiPicker()}
                {onReply && (
                  <Tooltip title="Reply in thread" disableInteractive>
                    <IconButton onClick={onReply} size="small">
                      <Reply sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                {onMore && (
                  <Tooltip title="More actions" disableInteractive>
                    <IconButton onClick={onMore} size="small">
                      <MoreHoriz sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                {Actions}
              </Stack>
            )}
          </Stack>
        </Stack>
      </>
    );

  return (
    <>
      {_renderDate()}
      <Stack
        direction="column"
        sx={{
          mb: 2,
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Stack direction="row" sx={{ alignItems: 'center', width: '100%', pl: 3 }}>
          <Stack direction="column" sx={{ width: '100%' }}>
            {show_name && !is_sender && <UserName sx={{ ml: '33px', mb: '2px', mt: '4px' }}>{username}</UserName>}
            <Stack
              direction={is_sender ? 'row-reverse' : 'row'}
              sx={{
                alignItems: 'center',
              }}
            >
              {!is_sender && (
                <AttendeeAvatar
                  size="tiny"
                  display_name={username}
                  avatar_url={avatar_url}
                  sx={{
                    width: '28px',
                    height: '28px',
                    flexShrink: 0,
                    alignSelf: 'flex-end',
                    mr: '5px',
                    ...(!show_avatar && { visibility: 'hidden' }),
                  }}
                />
              )}
              <Stack
                direction="column"
                ref={messageRef}
                sx={{ maxWidth: 400 }}
                alignItems={is_sender ? 'flex-end' : 'flex-start'}
                spacing="2px"
              >
                {override ? (
                  <Box
                    sx={{
                      borderRadius: '16px',
                      border: 1,
                      borderColor: is_sender ? 'primary.main' : 'divider',
                      ...(is_sender ? { borderBottomRightRadius: 0 } : { borderBottomLeftRadius: 0 }),
                    }}
                  >
                    <Typography
                      // color={is_sender && 'secondary'}
                      sx={{ padding: '6px 12px', fontStyle: 'italic' }}
                      variant="body2"
                    >
                      {override}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: '16px',
                      bgcolor: is_sender ? 'primary.main' : 'divider',
                      ...(is_sender ? { borderBottomRightRadius: 0 } : { borderBottomLeftRadius: 0 }),
                    }}
                  >
                    {reply_to && (
                      <Box
                        sx={{
                          bgcolor: 'background.default',
                          borderRadius: '14px',
                          borderWidth: 3,
                          borderStyle: 'solid',
                          borderColor: is_sender ? 'primary.main' : 'divider',
                          p: 1,
                          pt: 0,
                        }}
                      >
                        <Typography component="div" variant="caption" color="primary">
                          {reply_to.name}
                        </Typography>
                        {reply_to.Attachments}
                        <Message sx={{ padding: 0 }}>{reply_to.message}</Message>
                      </Box>
                    )}
                    <Stack
                      sx={
                        is_sender
                          ? {
                              color: 'primary.contrastText',
                            }
                          : {}
                      }
                    >
                      {Audio}
                      {message && <Message>{message}</Message>}
                      {subtext && (
                        <Typography
                          variant="caption"
                          sx={{ px: '12px', pb: '2px', fontStyle: 'italic', alignSelf: is_sender ? 'flex-end' : 'flex-start' }}
                        >
                          {subtext}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}
                {Attachments}
              </Stack>

              <Stack
                direction="row"
                className="actions"
                sx={{
                  h: '100%',
                  mx: '5px',
                  '& > *': {
                    opacity: isHovering ? 1 : 0,
                  },
                }}
              >
                {onReact && _renderEmojiPicker()}
                {onReply && (
                  <Tooltip title={quoteTooltip} disableInteractive>
                    <ListItemIconButton onClick={onReply} size="small">
                      <Reply sx={{ fontSize: 18 }} />
                    </ListItemIconButton>
                  </Tooltip>
                )}
                {Actions}
              </Stack>
            </Stack>
            {Reactions && (
              <Stack
                direction="row"
                sx={{
                  ...(is_sender && { justifyContent: 'flex-end' }),
                  ml: is_sender ? 0 : '40px',
                  // mt: "-5px",
                }}
              >
                {Reactions}
              </Stack>
            )}
            {Replies && (
              <Stack
                direction="row"
                sx={{
                  ...(is_sender && { justifyContent: 'flex-end' }),
                  ml: is_sender ? 0 : '35px',
                  mt: '2px',
                  // mt: "-5px",
                }}
              >
                {Replies}
              </Stack>
            )}
          </Stack>
          <Typography variant="caption" sx={{ minWidth: '60px', ml: 1, textAlign: 'right' }}>
            {dayjs(timestamp).format('HH:mm')}
          </Typography>
          <Tooltip title={status_tooltip} disableInteractive>
            <Box sx={{ width: '30px', px: '5px', lineHeight: 1 }}>
              {status && is_sender && <ImStatusIcon status={status} sx={{ fontSize: 16 }} />}
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
}
