import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LinkedinBasicAuthFormData, TokenPayload } from "../../type";
import AvailableProxyCountries from "../login/linkedin/steps/AvailableProxyCountries.json";

type LocalizationCountryOptionProps = {
  selected: boolean;
};

const LocalizationCountryOption: React.FC<LocalizationCountryOptionProps> = ({
  selected,
}) => {
  const { control } = useFormContext<LinkedinBasicAuthFormData>();
  return (
    <Box>
      <FormControlLabel
        value="country"
        control={<Radio size="small" />}
        label="Choose from available countries"
      />
      {selected && (
        <Controller
          name="country"
          control={control}
          defaultValue="auto"
          render={({ onChange, value, ...props }) => (
            <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2 }}>
              <Select
                id="country"
                value={value}
                size="small"
                onChange={onChange}
                displayEmpty
                {...props}
                SelectDisplayProps={{ style: { gap: 8, display: "flex" } }}
                MenuProps={{ sx: { maxHeight: "20rem" } }}
              >
                <MenuItem value="auto">
                  <em>(Default) Based on IP address</em>
                </MenuItem>
                {Object.entries(AvailableProxyCountries)
                  .sort(([, previous], [, next]) =>
                    previous > next ? 1 : previous < next ? -1 : 0,
                  )
                  .map(([code, name]) => (
                    <MenuItem
                      key={code}
                      value={code.toUpperCase()}
                      sx={{ gap: 4 }}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${code}.png 2x`}
                        src={`https://flagcdn.com/w20/${code}.png`}
                        alt=""
                      />
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </Box>
  );
};

type LocalizationProxyOptionProps = {
  selected: boolean;
};

const LocalizationProxyOption: React.FC<LocalizationProxyOptionProps> = ({
  selected,
}) => {
  const { control } = useFormContext();
  return (
    <Box>
      <FormControlLabel
        value="proxy"
        control={<Radio size="small" />}
        label="Use your own proxy"
      />
      {selected && (
        <Stack marginY={2} spacing={3}>
          <Controller
            name="protocol"
            control={control}
            defaultValue="http"
            render={({ onChange, value, ...props }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="protocol-label" sx={{ fontSize: 14 }}>
                  Protocol
                </InputLabel>
                <Select
                  labelId="protocol-label"
                  id="protocol"
                  value={value}
                  onChange={onChange}
                  {...props}
                  label="Protocol"
                >
                  <MenuItem value="http">HTTP</MenuItem>
                  <MenuItem value="https">HTTPS</MenuItem>
                  <MenuItem value="socks5">SOCKS5</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="domain"
            control={control}
            defaultValue=""
            render={({ onChange, value, ...props }) => (
              <TextField
                id="domain"
                label="Domain"
                InputLabelProps={{ sx: { fontSize: 14 } }}
                variant="outlined"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />

          <Controller
            name="port"
            control={control}
            defaultValue={0}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="port"
                label="Port"
                InputLabelProps={{ sx: { fontSize: 14 } }}
                variant="outlined"
                type="number"
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />

          <Controller
            name="usernameProxy"
            control={control}
            defaultValue=""
            render={({ onChange, value, ...props }) => (
              <TextField
                id="usernameProxy"
                label="Username"
                InputLabelProps={{ sx: { fontSize: 14 } }}
                variant="outlined"
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />

          <Controller
            name="passwordProxy"
            control={control}
            defaultValue=""
            render={({ onChange, value, ...props }) => (
              <TextField
                id="passwordProxy"
                label="Password"
                InputLabelProps={{ sx: { fontSize: 14 } }}
                variant="outlined"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
              />
            )}
          />
        </Stack>
      )}
    </Box>
  );
};

type LocalizationOptionsProps = {
  disabledOptions: Required<TokenPayload>["disabled_options"];
};

const LocalizationOptions: React.FC<LocalizationOptionsProps> = ({
  disabledOptions,
}) => {
  const [selected, setSelected] = useState<"country" | "proxy">(
    disabledOptions.includes("autoproxy") ? "proxy" : "country",
  );

  return (
    <RadioGroup
      value={selected}
      onChange={({ target }) => setSelected(target.value as typeof selected)}
    >
      {!disabledOptions.includes("autoproxy") && (
        <LocalizationCountryOption selected={selected === "country"} />
      )}
      {!disabledOptions.includes("proxy") && (
        <LocalizationProxyOption selected={selected === "proxy"} />
      )}
    </RadioGroup>
  );
};

export default LocalizationOptions;
