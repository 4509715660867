import { Alert, Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { solveCheckpoint } from "../../../../api/login";
import { CheckpointProps, CodeCheckpointFormData } from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";

const Twitter2FA = ({
  account_id,
  onCheckpoint,
  onSuccess,
}: CheckpointProps) => {
  const { control, formState, handleSubmit } =
    useForm<CodeCheckpointFormData>();

  const { isError, isLoading, error, mutate } = useMutation({
    mutationFn: solveCheckpoint,
    onSuccess: (data) => {
      data.object === "Checkpoint" && data.checkpoint.type !== "QRCODE"
        ? onCheckpoint(
            data.checkpoint.type === "CAPTCHA"
              ? {
                  type: "CAPTCHA",
                  public_key: data.checkpoint.public_key,
                  data: data.checkpoint.data,
                }
              : data.checkpoint.type === "CONTRACT_CHOOSER"
              ? {
                  type: "CONTRACT_CHOOSER",
                  contractList: data.checkpoint.contract_options,
                }
              : { type: data.checkpoint.type },
          )
        : onSuccess();
    },
  });

  const onSubmit = (data: CodeCheckpointFormData) => {
    const body = {
      provider: "TWITTER",
      code: data.code.trim(),
      account_id: account_id,
    };

    mutate(body);
  };

  return (
    <Stack p={6} spacing={6} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" pr={10}>
        Enter the code you see on your authenticator app
      </Typography>
      {isError && (
        <Typography color="error" sx={{ marginY: "16px" }} textAlign="center">
          {displayErrorMessage(error)}
        </Typography>
      )}
      <Controller
        name="code"
        control={control}
        defaultValue=""
        rules={{ required: `2FA Code is required` }}
        render={({ onChange, value, ...props }) => (
          <TextField
            id="code"
            placeholder="Code"
            value={value}
            onChange={onChange}
            {...props}
            error={!!formState.errors.code}
            helperText={formState.errors.code?.message}
          />
        )}
      />
      {isError && (
        <Alert severity="error" variant="filled">
          {displayErrorMessage(error)}
        </Alert>
      )}
      <SubmitButtons submitText="Submit" isLoading={isLoading} />
    </Stack>
  );
};

export default Twitter2FA;
