import axios from "axios";
import { api } from ".";
import {
  AccountCheckStatus,
  AccountType,
  AuthenticateRequestBody,
  AuthenticateResponse,
  CheckpointRequestBody,
  ImapConnectionParams,
  ResendCheckpointResponse,
  SolveCheckpointResponse,
  getOAuthURL,
} from "../type";

export const authenticateAccount = async ({
  body,
  accountIdToReconnect,
}: {
  body: AuthenticateRequestBody;
  accountIdToReconnect?: string | undefined | null;
}): Promise<AuthenticateResponse> => {
  const url = accountIdToReconnect
    ? `/hosted/accounts/${accountIdToReconnect}`
    : `/hosted/accounts`;
  const { data } = await api.post(url, body);

  if (
    data.checkpoint?.type === "CAPTCHA" &&
    (!data.checkpoint.public_key || !data.checkpoint.data)
  ) {
    throw new Error(
      "The captcha resolution has failed. Please try again later or use the cookies authentication method.",
    );
  }

  return data;
};

export const solveCheckpoint = async (
  body: CheckpointRequestBody,
): Promise<SolveCheckpointResponse> => {
  const { data } = await api.post(`/hosted/accounts/checkpoint`, body);

  if (
    data.checkpoint?.type === "CAPTCHA" &&
    (!data.checkpoint.public_key || !data.checkpoint.data)
  ) {
    throw new Error(
      "The captcha resolution has failed. Please try again later or use the cookies authentication method.",
    );
  }

  return data;
};

export const resendCheckpoint = async (
  account_id: string,
  provider: "LINKEDIN" | "INSTAGRAM",
): Promise<ResendCheckpointResponse> => {
  const { data } = await api.post(`/hosted/accounts/checkpoint/resend`, { account_id, provider });
  return data;
};

export const getQrCode = async (
  accountType: AccountType,
  accountIdToReconnect?: string | undefined | null,
) => {
  const body = {
    provider: accountType,
  };

  const url = accountIdToReconnect
    ? `/hosted/accounts/${accountIdToReconnect}`
    : `/hosted/accounts`;
  const { data } = await api.post(url, body);

  return data;
};

export const getAccountStatus = async (
  account_id: string,
  accountIdToReconnect: string | undefined | null,
) => {
  const url = `/hosted/accounts/${account_id}/status`;

  const { data } = await api.get(url, {
    params: {
      isReconnectAccount: accountIdToReconnect ? true : false,
    },
  });

  return data as AccountCheckStatus;
};

function rebuildUrlWithPort() {
  const paramsPort = api.defaults.params?.port;

  let api_url = api.defaults.baseURL?.slice(0, -8);

  if (paramsPort) api_url += ":" + paramsPort;

  return api_url + "/api/v1/";
}

export const getOauthGoogle = async (accountIdToReconnect?: string) => {
  let redirect_uri = `${rebuildUrlWithPort()}hosted/google_auth_request_callback`;

  const origin = window.location.origin;

  const { data } = await api.get(`hosted/google_auth_request`, {
    params: {
      redirectUri: encodeURI(redirect_uri),
      hostedAuthUrl: encodeURI(origin),
      reconnect_id: accountIdToReconnect,
    },
  });

  return data as getOAuthURL;
};

export const getOauthMicrosoft = async (accountIdToReconnect?: string) => {
  let redirect_uri = `${rebuildUrlWithPort()}hosted/microsoft_auth_request_callback`;

  const origin = window.location.origin;

  const { data } = await api.get(`hosted/microsoft_auth_request`, {
    params: {
      redirectUri: encodeURI(redirect_uri),
      hostedAuthUrl: encodeURI(origin),
      reconnect_id: accountIdToReconnect,
    },
  });

  return data as getOAuthURL;
};

export const requestImapConnectionParams = async (email: string) => {
  const { data } = await api.post(`hosted/request_imap_params`, { email });

  return data as ImapConnectionParams;
};
