import { Alert, Stack, TextField } from "@mui/material";
import { MailBasicAuthFormData, MailFullAuthFormData } from "../../../type";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SubmitButtons from "../../common/SubmitButtons";
import {
  authenticateAccount,
  requestImapConnectionParams,
} from "../../../api/login";
import { displayErrorMessage } from "../../utils";
import { AppContext, AppContextType } from "../../../App";
import ICloudApplicationAlert from "./utils/ICloudApplicationAlert";
import YahooApplicationAlert from "./utils/YahooApplicationAlert";

export default function MailBasicForm({
  onNeedMoreInfos,
  onSuccess,
}: {
  onNeedMoreInfos: (full_form_data: Partial<MailFullAuthFormData>) => void;
  onSuccess: () => void;
}) {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;
  const form = useForm<MailBasicAuthFormData>({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const isError = !!error;
  const isLoading = !!loading;

  const [emailProvider, setEmailProvider] = useState<string | null>(null);

  const handleEmailInput = async () => {
    const email = form.getValues()["email"];
    if (!email || !email?.includes("@")) return;
    const provider = email.split("@")[1];
    if (provider.includes("yahoo")) setEmailProvider("YAHOO");
    else if (provider.includes("icloud")) setEmailProvider("ICLOUD");
    else setEmailProvider(null);
  };

  const handleSubmit = async (data: MailBasicAuthFormData) => {
    setLoading(true);
    setError(undefined);
    try {
      // Request imap config
      const params = await requestImapConnectionParams(data.email);

      // Imediatly try to authenticate if all found
      if (
        params.imap_host &&
        params.imap_port &&
        params.smtp_host &&
        params.smtp_port
      ) {
        await authenticateAccount({
          body: {
            provider: "MAIL",
            imap_port: params.imap_port,
            imap_host: params.imap_host,
            smtp_port: params.smtp_port,
            smtp_host: params.smtp_host,
            imap_encryption: params.imap_encryption || "default",
            imap_user: params.imap_user,
            smtp_user: params.smtp_user,
            imap_password: data.password,
            smtp_password: data.password,
            ...(defaultSyncLimit?.MAILING && {
              sync_limit: defaultSyncLimit.MAILING,
            }),
          },
        });

        onSuccess();
      } else {
        // Back up partial config otherwise
        onNeedMoreInfos({
          ...(params.imap_port && { imap_port: params.imap_port }),
          ...(params.imap_host && { imap_host: params.imap_host }),
          ...(params.smtp_port && { smtp_port: params.smtp_port }),
          ...(params.smtp_host && { smtp_host: params.smtp_host }),
          imap_encryption: params.imap_encryption || "default",
          imap_user: params.imap_user,
          imap_password: data.password,
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      onNeedMoreInfos({
        imap_encryption: "default",
        imap_user: data.email,
        imap_password: data.password,
      });
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      onChange={handleEmailInput}
      style={{ width: "100%" }}
    >
      <Stack spacing={4}>
        <Controller
          name="email"
          control={form.control}
          defaultValue=""
          rules={{ required: "Email is required" }}
          render={({ onChange, value, ...props }) => (
            <TextField
              id="email"
              placeholder="Email"
              type="email"
              value={value}
              onChange={onChange}
              fullWidth
              error={!!form.formState.errors.email}
              helperText={form.formState.errors.email?.message}
              {...props}
            />
          )}
        />

        {emailProvider === "ICLOUD" && <ICloudApplicationAlert />}
        {emailProvider === "YAHOO" && <YahooApplicationAlert />}

        <Controller
          name="password"
          control={form.control}
          defaultValue=""
          rules={{ required: "Password is required" }}
          render={({ onChange, value, ...props }) => (
            <TextField
              id="password"
              placeholder="Password"
              type="password"
              fullWidth
              value={value}
              onChange={onChange}
              {...props}
              error={!!form.formState.errors.password}
              helperText={form.formState.errors.password?.message}
            />
          )}
        />
        {isError && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons submitText="Login" isLoading={isLoading} />
      </Stack>
    </form>
  );
}
