import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Container
      disableGutters
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: {
          xs: "90%",
          sm: "100%",
        },
      }}
      maxWidth="xs"
    >
      <Outlet />
    </Container>
  );
};

export default Layout;
