import { WizardSteps } from "apps/portalAccount/src/type";
import { useState } from "react";
import AddAccountFail from "../../common/AddAccountFail";
import AddAccountSuccess from "../../common/AddAccountSuccess";
import Messenger2FA from "./steps/Messenger2FA";
import MessengerLoginContainer from "./steps/MessengerLoginContainer";

const MessengerWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect?: string | undefined | null;
}) => {
  const [accountId, setAccountId] = useState<string>();
  const [step, setStep] = useState<WizardSteps>("LOGIN");

  if (step === "LOGIN")
    return (
      <MessengerLoginContainer
        accountIdToReconnect={
          accountIdToReconnect ? accountIdToReconnect : undefined
        }
        onSuccess={(account_id) => {
          setAccountId(account_id);
          setStep("COMPLETED");
        }}
        onCheckpoint={(account_id, checkpoint) => {
          setAccountId(account_id);
          setStep(checkpoint.type);
        }}
      />
    );

  if (step === "2FA" && accountId)
    return (
      <Messenger2FA
        account_id={accountId}
        onSuccess={() => {
          setStep("COMPLETED");
        }}
        onCheckpoint={({ type }) => {
          setStep(type);
        }}
      />
    );

  if (step === "COMPLETED")
    return (
      <AddAccountSuccess isReconnect={accountIdToReconnect ? true : false} />
    );

  return <AddAccountFail />;
};

export default MessengerWizard;
