import {
  Avatar,
  Button,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AccountIcon } from "@unipile/web-ui";
import { Dispatch, SetStateAction, useContext } from "react";

import { AccountType } from "../type";
import Container from "./Container";
import { AppContext, AppContextType } from "../App";

const MESSAGING_PROVIDERS: AccountType[] = [
  "INSTAGRAM",
  "LINKEDIN",
  "MESSENGER",
  "TELEGRAM",
  "TWITTER",
  "WHATSAPP",
];

const MAILING_PROVIDERS: AccountType[] = ["MAIL", "OUTLOOK", "GOOGLE"];

export const NAME: Record<AccountType, string> = {
  GOOGLE: "Gmail",
  INSTAGRAM: "Instagram",
  LINKEDIN: "LinkedIn",
  MAIL: "IMAP",
  MESSENGER: "Facebook Messenger",
  OUTLOOK: "Microsoft Outlook",
  TELEGRAM: "Telegram",
  TWITTER: "X (Twitter)",
  WHATSAPP: "WhatsApp",
};

type AccountTypeSelectionProps = {
  providers: AccountType[];
  failureUrl: string | null;
  type: string;
};

export const AccountTypeSelection: React.FC<AccountTypeSelectionProps> = ({
  providers,
  failureUrl,
  type,
}) => {
  const { setSelectedProvider } = useContext(AppContext) as AppContextType;

  const messagingProviders = providers.filter((p) =>
    MESSAGING_PROVIDERS.includes(p),
  );
  const mailingProviders = providers.filter((p) =>
    MAILING_PROVIDERS.includes(p),
  );

  return (
    <Container
      title={
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {type === "create" ? "Connect" : "Reconnect"} a new account
        </Typography>
      }
    >
      <Stack py={6} px={20} spacing={6}>
        <Typography variant="h6" textAlign="center">
          Select the type of account you want to connect
        </Typography>
        <Stack spacing={1}>
          {messagingProviders.map((provider) => (
            <Item
              key={provider}
              acc={provider}
              onClick={() => setSelectedProvider(provider)}
            />
          ))}
        </Stack>
        {messagingProviders.length > 0 && mailingProviders.length > 0 && (
          <Divider />
        )}
        <Stack spacing={1}>
          {mailingProviders.map((provider) => (
            <Item
              key={provider}
              acc={provider}
              onClick={() => setSelectedProvider(provider)}
            />
          ))}
        </Stack>
        {failureUrl && (
          <Link href={failureUrl}>
            <Button variant="outlined" fullWidth>
              Cancel
            </Button>
          </Link>
        )}
      </Stack>
    </Container>
  );
};

function Item({ acc, onClick }: { acc: AccountType; onClick: () => void }) {
  return (
    <Paper
      sx={{
        position: "relative",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "background.paper",
        },
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={4}
        sx={{
          p: 2,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{ width: 28, height: 28 }}
          src={AccountIcon[acc]}
        />
        <Typography variant="body2" align="center">
          {NAME[acc]}
        </Typography>
      </Stack>
    </Paper>
  );
}
