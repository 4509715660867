import { Avatar, Link, Stack, Typography } from "@mui/material";
import { AccountIcon } from "@unipile/web-ui";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { AppContext, AppContextType } from "../App";
import { NAME } from "./AccountTypeSelection";
import Container from "./Container";
import GoogleWizard from "./login/google/GoogleWizard";
import InstagramWizard from "./login/instagram/InstagramWizard";
import LinkedinWizard from "./login/linkedin/LinkedinWizard";
import MailWizard from "./login/mail/MailWizard";
import MessengerWizard from "./login/messenger/MessengerWizard";
import OutlookWizard from "./login/outlook/OutlookWizard";
import TelegramWizard from "./login/telegram/TelegramWizard";
import TwitterWizard from "./login/twitter/TwitterWizard";
import WhatsAppWizard from "./login/whatsapp/WhatsAppWizard";

interface LoginProps {
  type: "reconnect" | "create";
}

const Login: React.FC<LoginProps> = ({ type }) => {
  const { selectedProvider, reconnectValues, setSelectedProvider } = useContext(
    AppContext,
  ) as AppContextType;

  if (!selectedProvider) return null;

  return (
    <>
      <Helmet>
        <title>
          {reconnectValues?.accountId ? "Reconnect" : "Connect"} an account
        </title>
      </Helmet>
      <Container
        title={
          <Stack direction="row" alignItems="center" spacing={3}>
            <Avatar
              variant="rounded"
              src={AccountIcon[selectedProvider]}
              sx={{ width: 22, height: 22 }}
            />
            <Typography variant="subtitle2" sx={{ marginBottom: "4px" }}>
              {type === "reconnect" ? "Reconnect" : "Sign in"} to{" "}
              {NAME[selectedProvider]}
            </Typography>
          </Stack>
        }
      >
        {selectedProvider === "LINKEDIN" && (
          <LinkedinWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "WHATSAPP" && (
          <WhatsAppWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "MESSENGER" && (
          <MessengerWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "INSTAGRAM" && (
          <InstagramWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "TELEGRAM" && (
          <TelegramWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "TWITTER" && (
          <TwitterWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "MAIL" && (
          <MailWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "GOOGLE" && (
          <GoogleWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
        {selectedProvider === "OUTLOOK" && (
          <OutlookWizard accountIdToReconnect={reconnectValues?.accountId} />
        )}
      </Container>
    </>
  );
};

export default Login;
