import {
  Alert,
  Checkbox,
  FormControlLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { solveCheckpoint } from "../../../../api/login";
import { CodeCheckpointFormData, CheckpointProps } from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";
import { TabPanel } from "../../../common/TabPanel";
import { useState } from "react";

const Messenger2FA = ({
  account_id,
  onCheckpoint,
  onSuccess,
}: CheckpointProps) => {
  const { control, formState, handleSubmit } =
    useForm<CodeCheckpointFormData>();

  const { isError, isLoading, error, mutate } = useMutation({
    mutationFn: solveCheckpoint,
    onSuccess: (data) => {
      data.object === "Checkpoint" && data.checkpoint.type !== "QRCODE"
        ? onCheckpoint(
            data.checkpoint.type === "CAPTCHA"
              ? {
                  type: "CAPTCHA",
                  public_key: data.checkpoint.public_key,
                  data: data.checkpoint.data,
                }
              : data.checkpoint.type === "CONTRACT_CHOOSER"
              ? {
                  type: "CONTRACT_CHOOSER",
                  contractList: data.checkpoint.contract_options,
                }
              : { type: data.checkpoint.type },
          )
        : onSuccess();
    },
  });

  const onSubmit = (data: CodeCheckpointFormData) => {
    const inAppValidation = data.in_app_validation;
    const body = {
      provider: "MESSENGER",
      code: inAppValidation === true ? "in_app_validation" : data.code.trim(),
      account_id: account_id,
    };

    mutate(body);
  };

  const [tabs, setTabs] = useState(0);

  return (
    <Stack p={6} spacing={6} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" pr={10}>
        Enter authenticator code
      </Typography>
      {isError && (
        <Typography color="error" sx={{ marginY: "16px" }} textAlign="center">
          {displayErrorMessage(error)}
        </Typography>
      )}
      <Tabs
        value={tabs}
        onChange={(_, newValue) => setTabs(newValue)}
        aria-label="Validation methods"
        variant="fullWidth"
      >
        <Tab label="Code" />
        <Tab label="In Application" />
      </Tabs>
      <TabPanel sx={{ width: "100%" }} value={tabs} index={0}>
        <Stack direction="column">
          <Controller
            name="code"
            control={control}
            defaultValue=""
            // rules={{ required: `2FA Code is required` }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="code"
                placeholder="Code"
                value={value}
                onChange={onChange}
                {...props}
                error={!!formState.errors.code}
                helperText={formState.errors.code?.message}
              />
            )}
          />
          <Typography variant="caption">
            Note: the text message may take several minutes to arrive.
          </Typography>
        </Stack>
      </TabPanel>
      <TabPanel sx={{ width: "100%" }} value={tabs} index={1}>
        <Controller
          name="in_app_validation"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <FormControlLabel
              label="Approve your connexion in your app"
              control={
                <Checkbox
                  size="small"
                  name="in_app_validation"
                  checked={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.checked);
                  }}
                />
              }
            />
          )}
        />
      </TabPanel>

      {isError && (
        <Alert severity="error" variant="filled">
          {displayErrorMessage(error)}
        </Alert>
      )}
      <SubmitButtons submitText="Submit" isLoading={isLoading} />
    </Stack>
  );
};

export default Messenger2FA;
