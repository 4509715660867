import {
  Alert,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CheckpointStepProps, PhoneCheckpointFormData } from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";
import DiallingCodesMap from "./DiallingCodesMap.json";

const LinkedinPhoneRegister = ({
  error,
  isLoading,
  onSubmit,
}: Omit<CheckpointStepProps, "accountId">) => {
  const { control, formState, handleSubmit } =
    useForm<PhoneCheckpointFormData>();

  return (
    <Stack
      p={6}
      spacing={2}
      component="form"
      onSubmit={handleSubmit(({ phoneNumber, diallingCode }) =>
        onSubmit(`(${diallingCode})${phoneNumber}`),
      )}
    >
      <Typography variant="h3" pr={10}>
        Confirm your identity
      </Typography>
      <Stack spacing={4} pt={6}>
        <Controller
          name="diallingCode"
          control={control}
          defaultValue=""
          rules={{ required: "Country is required" }}
          render={({ onChange, value, ...props }) => (
            <Select
              size="small"
              displayEmpty
              MenuProps={{ style: { maxHeight: 400 } }}
              value={value}
              id="diallingCode"
              {...props}
              onChange={onChange}
              error={!!formState.errors.diallingCode}
            >
              <MenuItem value="">Select your country</MenuItem>
              {Object.entries(DiallingCodesMap).map(
                ([diallingCode, { code, country }]) => (
                  <MenuItem key={`country-option-${code}`} value={diallingCode}>
                    {country}
                  </MenuItem>
                ),
              )}
            </Select>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          rules={{ required: "Phone number is required" }}
          render={({ onChange, value, ...props }) => (
            <TextField
              placeholder="Enter your phone number"
              id="phoneNumber"
              type="tel"
              inputProps={{ pattern: "^[0-9]+$" }}
              value={value}
              onChange={onChange}
              {...props}
              error={!!formState.errors.phoneNumber}
              helperText={"You will receive a 2FA verification code"}
            />
          )}
        />
        {error && (
          <Alert severity="error" variant="filled">
            {displayErrorMessage(error)}
          </Alert>
        )}
        <SubmitButtons submitText="Submit" isLoading={isLoading} />
      </Stack>
    </Stack>
  );
};

export default LinkedinPhoneRegister;
