import { styled, Tooltip } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const StyledDiv = styled('div')(({ theme }) => ({
  borderRadius: '4px',
  fontSize: 16,
  // backgroundColor: theme.palette.divider,
  // paddingLeft: '4px',
  // paddingRight: '4px',
  paddingTop: '1px',
  lineHeight: 1,
  color: theme.palette.text.primary,
}));

type Props = {
  tooltip?: string;
};

export function ListItemLabel({ children, tooltip }: PropsWithChildren<Props>) {
  return (
    <Tooltip title={tooltip}>
      <StyledDiv>{children}</StyledDiv>
    </Tooltip>
  );
}
