import { Avatar, Box, BoxProps, Stack, styled, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title?: string;
  picture?: string;
  Blocks?: JSX.Element[];
  Actions?: JSX.Element;
  Tags?: JSX.Element;
};

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  border: `solid 1px ${theme.palette.divider}`,
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
}));

export function ContactContainer({ title, Blocks, Actions, Tags, picture }: Props) {
  return (
    <Stack spacing={2} p={2} bgcolor="background.paper" minHeight="100%">
      <Container p={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar src={picture} sx={{ width: 60, height: 60 }} />
          <Stack sx={{ flex: 1, overflow: 'hidden' }}>
            <Typography variant="h2" noWrap>
              {title}
            </Typography>
            {Tags && (
              <Stack direction="row" alignItems="center" mt={1}>
                {Tags}
              </Stack>
            )}
          </Stack>
          {Actions && (
            <Stack direction="row" alignItems="center">
              {Actions}
            </Stack>
          )}
        </Stack>
      </Container>
      {Blocks?.map((B) => (
        <Container>{B}</Container>
      ))}
    </Stack>
  );
}
