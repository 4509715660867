import { Alert, Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { authenticateAccount } from "../../../../api/login";
import { TwitterBasicAuthFormData, StepProps } from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import { displayErrorMessage } from "../../../utils";

const TwitterLoginContainer = ({
  onSuccess,
  onCheckpoint,
  accountIdToReconnect,
}: StepProps & { accountIdToReconnect: string | undefined }) => {
  return (
    <Stack p={6} spacing={3} alignItems="center">
      <Typography variant="h6">Enter your credentials</Typography>
      <AuthWithCredentialsForm
        onSuccess={onSuccess}
        onCheckpoint={onCheckpoint}
        accountIdToReconnect={accountIdToReconnect}
      />
    </Stack>
  );
};

function AuthWithCredentialsForm({
  onSuccess,
  onCheckpoint,
  accountIdToReconnect,
}: StepProps & { accountIdToReconnect: string | undefined }) {
  const form = useForm<TwitterBasicAuthFormData>();

  const { isError, isLoading, error, mutate } = useMutation({
    mutationFn: authenticateAccount,
    onSuccess: (data) => {
      data.object === "Checkpoint" &&
      data.checkpoint.type !== "QRCODE" &&
      data.checkpoint.type !== "CAPTCHA" &&
      data.checkpoint.type !== "CONTRACT_CHOOSER"
        ? onCheckpoint(data.account_id, { type: data.checkpoint.type })
        : onSuccess(data.account_id);
    },
  });

  const handleLogin = (data: TwitterBasicAuthFormData) => {
    let body = {
      provider: "TWITTER",
      username: data.username.trim(),
      email: data.email.trim(),
      password: data.password.trim(),
    } as const;

    mutate({ body, accountIdToReconnect });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleLogin)} style={{ width: "100%" }}>
        <Stack spacing={4}>
          <Controller
            name="username"
            control={form.control}
            defaultValue=""
            rules={{ required: "Username is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="username"
                placeholder="Username"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.username}
                helperText={form.formState.errors.username?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="email"
            control={form.control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="email"
                placeholder="Email"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.email}
                helperText={form.formState.errors.email?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="password"
            control={form.control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="password"
                placeholder="Password"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.password}
                helperText={form.formState.errors.password?.message}
              />
            )}
          />
          <Alert severity="error">
            This provider is no longer maintained. Authentication may not work
            as expected.
          </Alert>
          {isError && (
            <Alert severity="error" variant="filled">
              {displayErrorMessage(error)}
            </Alert>
          )}
          <SubmitButtons submitText="Login" isLoading={isLoading} />
        </Stack>
      </form>
    </FormProvider>
  );
}

export default TwitterLoginContainer;
