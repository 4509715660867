import { CircularProgress, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { WizardSteps } from "apps/portalAccount/src/type";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOauthGoogle } from "../../../api/login";
import AddAccountFail from "../../common/AddAccountFail";
import AddAccountSuccess from "../../common/AddAccountSuccess";

const GoogleWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect?: string | undefined;
}) => {
  const { isError, isLoading, data, error } = useQuery<
    Awaited<ReturnType<typeof getOauthGoogle>>,
    Error
  >(
    ["getOauthGoogle"],
    async () => {
      return await getOauthGoogle(accountIdToReconnect);
    },
    {
      onSuccess: (data) => {
        window.location.href = data.url;
      },
    },
  );

  if (isError) return <AddAccountFail message={error.message} />;

  return (
    <Stack justifyContent="center" alignItems="center" flex="1" spacing={2}>
      <CircularProgress color="inherit" />
      <Typography variant="body2">Redirecting to Google.com</Typography>
    </Stack>
  );
};

export default GoogleWizard;
