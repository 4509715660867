import axios from "axios";
import { isDev } from "../utils/isDev";

export const api = axios.create({
  headers: {
    ContentType: "application/json",
    accept: "application/json",
  },
});

export const setDefaultAxiosConfig = (token: string, url: string) => {
  const [http, server, port] = url.split(":");
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("url", url);

  if (!isDev) {
    api.defaults.baseURL = `${http}:${server}/api/v1/`;
    api.defaults.params = { port };
  } else {
    api.defaults.baseURL = `${http}:${server}:${port}/api/v1/`;
  }

  api.defaults.headers.common["X-API-KEY"] = token;
};
