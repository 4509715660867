import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import CheckpointResendButton from "../../../CheckpointResendButton";

/**
 * @todo Component must periodicaly get status untily succeed
 */
const LinkedinInAppValidation = ({
  onSubmit,
  accountId,
}: {
  onSubmit: () => void;
  accountId: string;
}) => {
  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <Stack
      flex={1}
      p={6}
      spacing={6}
      justifyContent="center"
      alignItems={"center"}
    >
      <Stack alignItems={"center"}>
        <Typography variant="h3">Please confirm Sign in</Typography>
        <Typography variant="h6">Check your Linkedin Mobile App</Typography>
      </Stack>
      <MobileFriendlyIcon sx={{ fontSize: "80px" }} />
      <CheckpointResendButton
        account_id={accountId}
        provider="LINKEDIN"
        label="Resend notification"
      />
    </Stack>
  );
};

export default LinkedinInAppValidation;
