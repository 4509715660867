import {
  Badge,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  Avatar as MuiAvatar,
} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { ReactNode, useMemo } from 'react';
import { AttendeeAvatar } from './AttendeeAvatar';
import { ImStatus, ImStatusIcon } from './ImStatusIcon';
import { SmallAvatar } from './SmallAvatar';

dayjs.extend(relativeTime);
type Props = {
  Avatar?: JSX.Element;
  is_group?: boolean;
  icon?: string;
  AccountIcon?: JSX.Element;
  date?: ReactNode;
  title?: string;
  message?: string | ReactNode | null;
  messageInfo?: string | undefined;
  status?: ImStatus;
  Label?: JSX.Element;
  Tags?: JSX.Element;
  unread_count?: number;
  lines?: number;
  overline?: string;
};

export function ImThreadListItem({
  title,
  message,
  messageInfo,
  is_group = false,
  icon,
  AccountIcon,
  Avatar,
  date,
  status,
  Label,
  Tags,
  unread_count,
  lines = 1,
  overline,
  ...rest
}: Props & ListItemButtonProps) {
  const unread = (typeof unread_count !== 'undefined' && unread_count !== 0) || false;
  const read = !unread;

  return (
    <ListItemButton {...rest}>
      <ListItemAvatar>
        <MuiAvatar sx={{ width: 20, height: 20 }} variant="rounded" src={icon} />
      </ListItemAvatar>
      <ListItemAvatar>{Avatar}</ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <span>{title || '?'}</span>
            <Typography variant="caption" color={read ? 'text.secondary' : 'text.primary'} fontWeight={read ? 400 : 700}>
              {date}
            </Typography>
          </Stack>
        }
        secondary={
          <Stack direction="row" alignItems="center">
            {status && <ImStatusIcon sx={{ fontSize: 18, marginRight: '4px' }} status={status} />}
            <Typography variant="body2" noWrap fontWeight={read ? 400 : 600} color={read ? 'text.secondary' : 'text.primary'}>
              {message || '.'}
            </Typography>
            {unread && (
              <Chip
                label={unread_count && unread_count > 0 ? unread_count : ''}
                size="small"
                color="primary"
                sx={{ ...(unread_count < 0 && { minWidth: 22, flexShrink: 0 }) }}
              />
            )}
          </Stack>
        }
        primaryTypographyProps={{ fontWeight: read ? 500 : 600 }}
      />
    </ListItemButton>
  );
}
