import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { authenticateAccount } from "../../../../api/login";
import {
  AuthenticateRequestBody,
  MessengerBasicAuthFormData,
  StepProps,
} from "../../../../type";
import SubmitButtons from "../../../common/SubmitButtons";
import SyncLimitCheckbox from "../../../common/SyncLimitCheckbox";
import { displayErrorMessage } from "../../../utils";
import { AppContext, AppContextType } from "../../../../App";
import { useContext } from "react";
import { LoginOptions } from "../../../common/LoginOptions";

const MessengerLoginContainer = ({
  onSuccess,
  onCheckpoint,
  accountIdToReconnect,
}: StepProps & { accountIdToReconnect: string | undefined }) => {
  return (
    <Stack p={6} spacing={3} alignItems="center">
      <Typography variant="h6">Enter your credentials</Typography>
      <AuthWithCredentialsForm
        onSuccess={onSuccess}
        onCheckpoint={onCheckpoint}
        accountIdToReconnect={accountIdToReconnect}
      />
    </Stack>
  );
};

function AuthWithCredentialsForm({
  onSuccess,
  onCheckpoint,
  accountIdToReconnect,
}: StepProps & { accountIdToReconnect: string | undefined }) {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;
  const form = useForm<MessengerBasicAuthFormData>({
    defaultValues: {
      chats_sync: defaultSyncLimit?.MESSAGING?.chats !== 0,
      messages_sync: defaultSyncLimit?.MESSAGING?.messages !== 0,
    },
  });

  const { isError, isLoading, error, mutate } = useMutation({
    mutationFn: authenticateAccount,
    onSuccess: (data) => {
      data.object === "Checkpoint" &&
      data.checkpoint.type !== "QRCODE" &&
      data.checkpoint.type !== "CAPTCHA" &&
      data.checkpoint.type !== "CONTRACT_CHOOSER"
        ? onCheckpoint(data.account_id, { type: data.checkpoint.type })
        : onSuccess(data.account_id);
    },
  });

  const handleLogin = (data: MessengerBasicAuthFormData) => {
    const body: AuthenticateRequestBody = {
      provider: "MESSENGER",
      username: data.email.trim(),
      password: data.password.trim(),
      ...((!data.chats_sync || !data.messages_sync) && {
        sync_limit: {
          ...(!data.chats_sync && { chats: 0 }),
          ...(!data.messages_sync && { messages: 0 }),
        },
      }),
    };

    mutate({ body, accountIdToReconnect });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleLogin)} style={{ width: "100%" }}>
        <Stack spacing={4}>
          <Controller
            name="email"
            control={form.control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="email"
                placeholder="Email"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.email}
                helperText={form.formState.errors.email?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="password"
            control={form.control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="password"
                placeholder="Password"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.password}
                helperText={form.formState.errors.password?.message}
              />
            )}
          />

          <LoginOptions label="Optional settings">
            <SyncLimitCheckbox
              name="chats_sync"
              label="Synchronize chats"
              TooltipProps={{
                title: "Choose if the chat history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <SyncLimitCheckbox
              name="messages_sync"
              label="Synchronize messages"
              TooltipProps={{
                title: "Choose if the messages history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
          </LoginOptions>

          <Alert severity="error">
            This provider is no longer maintained. Authentication may not work
            as expected.
          </Alert>

          {isError && (
            <Alert severity="error" variant="filled">
              {displayErrorMessage(error)}
            </Alert>
          )}
          <SubmitButtons submitText="Login" isLoading={isLoading} />
        </Stack>
      </form>
    </FormProvider>
  );
}

export default MessengerLoginContainer;
