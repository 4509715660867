import { Alert, Divider, Stack, TextField, Typography } from "@mui/material";
import { AppContext, AppContextType } from "../../../../App";
import { useContext } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { InstagramBasicAuthFormData, LoginStepProps } from "../../../../type";
import { LoginOptions } from "../../../common/LoginOptions";
import SubmitButtons from "../../../common/SubmitButtons";
import SyncLimitCheckbox from "../../../common/SyncLimitCheckbox";
import { displayErrorMessage } from "../../../utils";

const InstagramLoginContainer: React.FC<
  LoginStepProps<InstagramBasicAuthFormData>
> = (props) => {
  return (
    <Stack p={6} spacing={3} alignItems="center">
      <Typography variant="h6">Enter your credentials</Typography>
      <AuthWithCredentialsForm {...props} />
    </Stack>
  );
};

const AuthWithCredentialsForm: React.FC<
  LoginStepProps<InstagramBasicAuthFormData>
> = ({ error, isLoading, onSubmit }) => {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;

  const form = useForm<InstagramBasicAuthFormData>({
    defaultValues: {
      chats_sync: defaultSyncLimit?.MESSAGING?.chats !== 0,
      messages_sync: defaultSyncLimit?.MESSAGING?.messages !== 0,
    },
  });

  // const { isError, isLoading, error, mutate } = useMutation({
  //   mutationFn: authenticateAccount,
  //   onSuccess: (data) => {
  //     data.object === "Checkpoint" &&
  //     data.checkpoint.type !== "QRCODE" &&
  //     data.checkpoint.type !== "CAPTCHA" &&
  //     data.checkpoint.type !== "CONTRACT_CHOOSER"
  //       ? onCheckpoint(data.account_id, { type: data.checkpoint.type })
  //       : onSuccess(data.account_id);
  //   },
  // });

  // const handleLogin = (data: InstagramBasicAuthFormData) => {
  //   const body: AuthenticateRequestBody = {
  //     provider: "INSTAGRAM",
  //     username: data.username.trim(),
  //     password: data.password.trim(),
  //     ...((!data.chats_sync || !data.messages_sync) && {
  //       sync_limit: {
  //         ...(!data.chats_sync && { chats: 0 }),
  //         ...(!data.messages_sync && { messages: 0 }),
  //       },
  //     }),
  //   };

  //   mutate({ body, accountIdToReconnect });
  // };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit((data) => onSubmit(data))}
        style={{ width: "100%" }}
      >
        <Stack spacing={4}>
          <Controller
            name="username"
            control={form.control}
            defaultValue=""
            rules={{ required: "Username is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="username"
                placeholder="Username"
                value={value}
                onChange={onChange}
                fullWidth
                error={!!form.formState.errors.username}
                helperText={form.formState.errors.username?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="password"
            control={form.control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ onChange, value, ...props }) => (
              <TextField
                id="password"
                placeholder="Password"
                type="password"
                fullWidth
                value={value}
                onChange={onChange}
                {...props}
                error={!!form.formState.errors.password}
                helperText={form.formState.errors.password?.message}
              />
            )}
          />
          {error && (
            <Alert severity="error" variant="filled">
              {displayErrorMessage(error)}
            </Alert>
          )}

          <LoginOptions label="Optional settings">
            <SyncLimitCheckbox
              name="chats_sync"
              label="Synchronize chats"
              TooltipProps={{
                title: "Choose if the chat history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
            <SyncLimitCheckbox
              name="messages_sync"
              label="Synchronize messages"
              TooltipProps={{
                title: "Choose if the messages history should be synced",
                placement: "left",
                enterDelay: 750,
              }}
              defaultValue
            />
          </LoginOptions>

          <Alert severity="info">
            <div>
              You need an Instagram account identifier. Facebook account login
              is not supported yet.{" "}
              <a href="https://accountscenter.facebook.com/">
                You can find it here
              </a>
            </div>
            <Divider sx={{ my: 2 }} />
            <div>
              You may experience issues with login. It is recommended to enable
              2FA using a code generation app.
            </div>
          </Alert>
          <SubmitButtons submitText="Login" isLoading={isLoading} />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default InstagramLoginContainer;
