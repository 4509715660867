import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import {
  authenticateAccount,
  getAccountStatus,
  getQrCode,
} from "../../../api/login";
import { AuthenticateResponse } from "../../../type";
import AddAccountSuccess from "../../common/AddAccountSuccess";
import Countdown from "../../common/Countdown";
import { displayErrorMessage } from "../../utils";

const TelegramWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect?: string | undefined | null;
}) => {
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState<string>();

  const handleRender = () => {
    switch (step) {
      case 0:
        return (
          <PasswordScreen
            onSubmit={(password) => {
              setPassword(password);
              setStep(1);
            }}
            onSkip={() => setStep(1)}
          />
        );
      default:
        return (
          <QrCodeScreen
            accountIdToReconnect={accountIdToReconnect}
            password={password}
          />
        );
    }
  };

  return (
    <Stack p={6} justifyContent="center" alignItems="center">
      {handleRender()}
    </Stack>
  );
};

const PasswordScreen = ({
  onSubmit,
  onSkip,
}: {
  onSubmit: (password: string) => void;
  onSkip: () => void;
}) => {
  const [password, setPassword] = useState("");

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Enter your 2FA Password
      </Typography>
      <TextField
        variant="outlined"
        type="password"
        placeholder="•••••••••"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ marginBottom: 6 }}
      />
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Button variant="outlined" onClick={() => onSkip()} fullWidth>
            Skip (I don't use 2FA)
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={() => onSubmit(password)}
            fullWidth
          >
            Submit
          </Button>{" "}
        </Grid>
      </Grid>
    </>
  );
};

const QrCodeScreen = ({
  accountIdToReconnect,
  password,
}: {
  accountIdToReconnect?: string | undefined | null;
  password: string | undefined;
}) => {
  const [isLoadingQr, setIsLoadingQr] = useState(true);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [qrcode, setQrCode] = useState("");
  const failureUrl = sessionStorage.getItem("failureUrl");

  const getQrCodeMutation = useMutation(
    async () => {
      return await authenticateAccount({
        body: password
          ? { provider: "TELEGRAM", password }
          : { provider: "TELEGRAM" },
        accountIdToReconnect,
      });
    },
    {
      onSuccess: (data: AuthenticateResponse) => {
        setIsLoadingQr(false);
        if (
          data.object === "Checkpoint" &&
          data.checkpoint.type === "QRCODE" &&
          data.checkpoint.qrcode
        ) {
          setQrCode(data.checkpoint.qrcode);
          getLoginStatusMutation.mutate(data.account_id);
        }
        setIsTimerEnded(false);
      },
    },
  );

  const getLoginStatusMutation = useMutation(async (account_id: string) => {
    return await getAccountStatus(account_id, accountIdToReconnect);
  });

  const handleGetQrCode = () => {
    getQrCodeMutation.mutate();
  };

  useEffect(() => {
    handleGetQrCode();
  }, []);

  const handleError = (error: unknown) => {
    if (
      error instanceof AxiosError &&
      error.response?.status === 504 &&
      error.response.statusText === "Gateway Timeout"
    ) {
      return "the QRcode is no longer valid";
    }

    displayErrorMessage(error);
  };

  if (getQrCodeMutation.isLoading || isLoadingQr) {
    return <CircularProgress />;
  }

  if (getLoginStatusMutation.isSuccess) {
    return (
      <AddAccountSuccess isReconnect={accountIdToReconnect ? true : false} />
    );
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Follow the steps to {accountIdToReconnect ? "reconnect" : "connect"}
      </Typography>
      {getQrCodeMutation.error && (
        <Typography color="error" sx={{ marginTop: 4 }}>
          Get Qrcode failed, {handleError(getQrCodeMutation.error)}
        </Typography>
      )}
      {getLoginStatusMutation.error && (
        <Typography color="error" textAlign="center">
          Connection failed, {handleError(getLoginStatusMutation.error)}
        </Typography>
      )}
      {isTimerEnded ? (
        <>
          <Button
            variant="contained"
            size="medium"
            onClick={() => handleGetQrCode()}
            sx={{ textTransform: "uppercase", marginTop: 4 }}
          >
            Retry
          </Button>
        </>
      ) : (
        <>
          <InstructionsTelegram />
          {qrcode && <QrcodeCanvas qrcode={qrcode} />}
          <Countdown initialTime={30} setIsTimerEnded={setIsTimerEnded} />
          {failureUrl && failureUrl !== "undefined" && (
            <Link href={failureUrl} marginTop={4}>
              <Button variant="outlined" fullWidth>
                Cancel
              </Button>
            </Link>
          )}
        </>
      )}
    </>
  );
};

const QrcodeCanvas = ({ qrcode }: { qrcode: string }) => {
  useEffect(() => {
    QRCode.toCanvas(canvasRef.current, qrcode);
  }, [qrcode]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  return <canvas ref={canvasRef} width={276} height={276}></canvas>;
};

const InstructionsTelegram = () => {
  return (
    <Stack spacing={1}>
      <Typography variant="body2">1. Open Telegram on your phone</Typography>
      <Typography variant="body2">
        2. Tap Menu then Settings and select Devices
      </Typography>
      <Typography variant="body2">3. Tap on Link Desktop Device</Typography>
      <Typography variant="body2">
        4. Point your phone to this screen to capture the code (you have 30
        seconds before the qr code is no longer valid)
      </Typography>
    </Stack>
  );
};

export default TelegramWizard;
