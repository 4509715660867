import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { api } from "../../api";

export default function ExpirationDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<
    "errors/expired_credentials" | "errors/invalid_credentials" | null
  >(null);
  const url = sessionStorage.getItem("failureUrl");

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error?.response?.data?.type) {
          case "errors/expired_credentials":
            setError("errors/expired_credentials");
            setOpen(true);
            break;
          // case "errors/invalid_credentials":
          //   setError("errors/invalid_credentials");
          //   setOpen(true);
          //   break;
        }

        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle>Session expired</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {error === "errors/expired_credentials"
            ? "Your authentication link or session has expired. Please request a new authentication link to continue."
            : "Your authentication link or session has expired or is invalid. Please request a new authentication link to continue."}
        </Typography>
      </DialogContent>
      {url && (
        <DialogActions>
          <Button href={url} LinkComponent="a">
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
