import {
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { LinkedinBasicAuthFormData } from "../../type";
import { useContext, useEffect } from "react";
import { AppContext, AppContextType } from "../../App";

type SyncLimitCheckboxProps = {
  name: "messages_sync" | "chats_sync";
  label: string;
  defaultValue: boolean;
  TooltipProps: Omit<TooltipProps, "children">;
};

const SyncLimitCheckbox: React.FC<SyncLimitCheckboxProps> = ({
  name,
  label,
  defaultValue,
  TooltipProps,
}) => {
  const { defaultSyncLimit } = useContext(AppContext) as AppContextType;
  const { control, setValue } = useFormContext<LinkedinBasicAuthFormData>();
  const shouldSyncChats = useWatch({
    control,
    name: "chats_sync",
    defaultValue: true,
  });

  useEffect(() => {
    if (name === "chats_sync")
      setValue("chats_sync", defaultSyncLimit?.MESSAGING?.chats !== 0);
    if (name === "messages_sync")
      setValue("messages_sync", defaultSyncLimit?.MESSAGING?.messages !== 0);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <Tooltip {...TooltipProps}>
            <FormControlLabel
              disabled={name === "messages_sync" && !shouldSyncChats}
              control={
                <Checkbox
                  size="small"
                  checked={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.checked;
                    if (name === "chats_sync" && !value)
                      setValue("messages_sync", false);
                    onChange(e.target.checked);
                  }}
                />
              }
              label={label}
            />
          </Tooltip>
        )}
      />
    </Box>
  );
};

export default SyncLimitCheckbox;
