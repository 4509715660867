import { Alert, Link, Snackbar } from "@mui/material";
import { resendCheckpoint } from "../api/login";

import React, { useState } from "react";

type CheckpointResendButtonProps = {
  label: string;
  provider: "LINKEDIN" | "INSTAGRAM";
  account_id: string;
};

const CheckpointResendButton: React.FC<CheckpointResendButtonProps> = ({
  label,
  provider,
  account_id,
}) => {
  const [message, setMessage] = useState<{
    error: boolean;
    text: string;
  } | null>(null);

  const handleResend = async () => {
    const resendResponse = await resendCheckpoint(account_id, provider);
    setMessage({
      error: !resendResponse.success,
      text: resendResponse.success
        ? "The checkpoint has successfully been resent."
        : "The checkpoint could not be resent.",
    });
  };

  return (
    <>
      <Link type="button" component={"button"} onClick={handleResend}>
        {label}
      </Link>
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          severity={message?.error ? "error" : "success"}
          onClose={() => setMessage(null)}
          sx={{ width: "100%" }}
        >
          {message?.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CheckpointResendButton;
