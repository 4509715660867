import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 error...</title>
      </Helmet>
      <Typography variant="h1">Error 404</Typography>
    </>
  );
};

export default NotFoundPage;
