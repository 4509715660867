import { Avatar, Stack, Typography } from "@mui/material";
import { AccountIcon } from "@unipile/web-ui";
import { Helmet } from "react-helmet";
import AddAccountFail from "../components/common/AddAccountFail";
import AddAccountSuccess from "../components/common/AddAccountSuccess";
import { useSearchParams } from "react-router-dom";
import Container from "../components/Container";

const OutlookOauth = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const is_reconnecting = searchParams.has("reconnect");
  return (
    <>
      <Helmet>
        <title>
          {is_reconnecting ? "Reconnect" : "Connect"} your Outlook account
        </title>
      </Helmet>
      <Container
        title={
          <Stack direction="row" alignItems="center" spacing={3}>
            <Avatar
              variant="rounded"
              src={AccountIcon["OUTLOOK"]}
              sx={{ width: 22, height: 22 }}
            />
            <Typography variant="subtitle2" sx={{ marginBottom: "4px" }}>
              Sign in to Outlook
            </Typography>
          </Stack>
        }
      >
        {error ? (
          <AddAccountFail message={error} />
        ) : (
          <AddAccountSuccess isReconnect={is_reconnecting} />
        )}
      </Container>
    </>
  );
};

export default OutlookOauth;
