import { Paper, Stack, Divider, Typography, List } from "@mui/material";
import React, { PropsWithChildren } from "react";

export default function Container({
  children,
  title,
}: PropsWithChildren<{ title: JSX.Element }>) {
  return (
    <Paper sx={{ width: "100%" }}>
      <Stack divider={<Divider />} minHeight={500}>
        <Stack p={2} direction="row" alignItems="center" spacing={3}>
          {title}
        </Stack>
        {children}
      </Stack>
    </Paper>
  );
}
