import { CircularProgress, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getOauthMicrosoft } from "../../../api/login";
import AddAccountFail from "../../common/AddAccountFail";

const OutlookWizard = ({
  accountIdToReconnect,
}: {
  accountIdToReconnect: string | undefined;
}) => {
  const { isError, isLoading, data, error } = useQuery<
    Awaited<ReturnType<typeof getOauthMicrosoft>>,
    Error
  >(
    ["getOauthMicrosoft"],
    async () => {
      return await getOauthMicrosoft(accountIdToReconnect);
    },
    {
      onSuccess: (data) => {
        window.location.href = data.url;
      },
    },
  );

  if (isError) return <AddAccountFail message={error.message} />;

  return (
    <Stack justifyContent="center" alignItems="center" flex="1" spacing={2}>
      <CircularProgress color="inherit" />
      <Typography variant="body2">Redirecting to Microsoft.com</Typography>
    </Stack>
  );
};

export default OutlookWizard;
